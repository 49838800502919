import React from "react";
import ServicePage from "../components/ServicePage";
import PageMeta from "../components/PageMeta";

export default function TechnologyPage() {
  return (
    <ServicePage>
      <PageMeta title="QCL - Technology" path="technology" />
      <h1 className="top">Technology</h1>
      <p className="lead">Did you know...</p>
      <p>QCL is so much more than just a top quality care provider.</p>
      <p>
        At QCL we understand that trust between the Service User and care
        provider is paramount. But QCL would never leave that trust to chance.
      </p>
      <p>
        That’s why, at QCL every one of our carers carries a mobile phone with
        NFC capabilities. This technology lets us monitor every arrival and
        departure time a carer makes by matching the phone to a ‘tag’ placed in
        the Service User’s residence. This allows QCL more time to better manage
        the Service User’s care package and more time to better manage QCL’s
        staff.
      </p>
      <p>
        When QCL looked for an NFC supported software package to enable us to do
        this, we could not find a product that met our demanding standards.
        Therefore, in line with QCL’s commitment to innovative care delivery, we
        commissioned a bespoke software package that was developed from the
        ground up.
      </p>
    </ServicePage>
  );
}
